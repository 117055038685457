<template>
  <h1>{{ $gettext('Le nostre macchine') }}</h1>

  <div v-if="loading">Caricamento lista macchine...</div>
  <div class="container" v-if="!loading">
    <div class="machines">
      <machine-card
        v-for="machine in machines"
        :key="machine.id"
        :title="machine.title"
        :src="machine.image"
        :id="machine.id"
      ></machine-card>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import MachineCard from '@/components/shared/MachineCard';

export default {
  name: 'machine-listing',
  components: { MachineCard },

  data() {
    return {
      machines: [],
      loading: false,
    };
  },

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },

    loadMachine() {
      axios
        .get(this.api.machineListing + "?"  + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          const machines = [];

          for (const index in res.data) {
            machines.push({
              id: res.data[index].id,
              title: this.domDecoder(res.data[index].title.rendered),
              image: '/images/placeholder-768x768.png',
            });
            this.loadImageURL(res.data[index].featured_media, index);
          }
          this.machines = machines;
        })

        .catch((error) => {
          console.log('Errror: ' + error);
        });
    },

    async loadImageURL(idMedia, idMachine) {
      let imageUrl = this.api.getMediaUrl + idMedia;

      await axios
        .get(imageUrl)
        .then((response) => {
          this.machines[idMachine].image =
            response.data.media_details.sizes.medium_large.source_url;
        })

        .catch((error) => {
          console.log('Errror: ' + error);
        });
    },
  },

  mounted() {
    this.loadMachine();
  },
};
</script>

<style scoped>
h1 {
  margin: 25px 0;
  font-size: 1.5rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--var-primary-color);
}

.machine {
  margin-bottom: 30px;
}
</style>
